import { useEffect, useState } from "react"
import useContentful from "./useContentful"
import {
  AppContainer,
  ContentContainer,
  LeftColumn,
  Loader,
} from "./StyledComponents"
import RightColumnForm from "./Components/RightColumnForm"
import ContactInfo from "./Components/ContactInfo"
import StaticsDisplay from "./Components/StaticsDisplay"
import MainInfo from "./Components/MainInfo"
import { useMediaQuery } from "./useMediaQuery"
import MobileForm from "./Components/MobileForm"

const App = () => {
  const [gosusluga, setGosusluga] = useState("")
  const { getAllData } = useContentful()
  const { contactInformation } = gosusluga || ""
  const { statics } = gosusluga || ""
  const { rus } = gosusluga || ""
  const { ukr } = gosusluga || ""
  const { gr } = gosusluga || ""
  const { kzh } = gosusluga || ""

  const isMobile992 = useMediaQuery(992)

  useEffect(() => {
    getAllData().then(response => response && setGosusluga(response))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AppContainer>
      <ContentContainer>
        <LeftColumn>
          {gosusluga ? (
            <>
              <ContactInfo contactInformation={contactInformation} />
              <MainInfo rus={rus} ukr={ukr} gr={gr} kzh={kzh} />
              {isMobile992 && <MobileForm />}
              <StaticsDisplay statics={statics} />
            </>
          ) : (
            <Loader />
          )}
        </LeftColumn>
        {!isMobile992 && <RightColumnForm />}
      </ContentContainer>
    </AppContainer>
  )
}

export default App
