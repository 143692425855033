import React from "react"
import {
  RightColumn,
  RightColumnHeader,
  RightColumnSubHeader,
  RightColumnInput,
  RightColumnSelect,
  RightColumnButton,
} from "../StyledComponents"

const RightColumnForm = () => {
  return (
    <RightColumn>
      <RightColumnHeader>Оставьте заявку</RightColumnHeader>
      <RightColumnSubHeader>
        Узнайте больше о преимуществах европейского гражданства
      </RightColumnSubHeader>
      <form action="https://formspree.io/f/maygjpqo" method="POST">
        <label>
          <RightColumnInput
            name="name"
            type="text"
            placeholder="Имя"
            required
          />
        </label>
        <label>
          <RightColumnInput
            name="contact"
            type="text"
            placeholder="WhatsApp / Telegram"
            required
          />
        </label>
        <label>
          <RightColumnSelect name="country" required>
            <option value="">Интересующая страна</option>
            <option value="Россия">Россия</option>
            <option value="Украина">Украина</option>
            <option value="Грузия">Грузия</option>
            <option value="Казахстан">Казахстан</option>
          </RightColumnSelect>
        </label>
        <RightColumnButton type="submit">Оставить заявку</RightColumnButton>
      </form>
    </RightColumn>
  )
}

export default RightColumnForm
