import React from "react"
import {
  LineWrapperStatics,
  IndividualLine,
  StaticsText,
  StaticsTextRight,
} from "../StyledComponents"

const StaticsDisplay = ({ statics }) => {
  if (!statics || statics.length === 0) return null

  return (
    <LineWrapperStatics>
      <IndividualLine>
        <StaticsText>{statics[0].year}</StaticsText>
        <StaticsTextRight>{statics[0].textYear}</StaticsTextRight>
      </IndividualLine>
      <IndividualLine>
        <StaticsText>{statics[0].clients}</StaticsText>
        <StaticsTextRight>{statics[0].textClients}</StaticsTextRight>
      </IndividualLine>
      <IndividualLine>
        <StaticsText>{statics[0].documentetion}</StaticsText>
        <StaticsTextRight>{statics[0].textDocumentetion}</StaticsTextRight>
      </IndividualLine>
    </LineWrapperStatics>
  )
}

export default StaticsDisplay
