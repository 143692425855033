import React from "react"
import {
  RightColumnHeader,
  RightColumnSubHeader,
  RightColumnInput,
  RightColumnSelect,
  RightColumnButton,
  LineWrapper,
  IndividualLine,
} from "../StyledComponents"

const MobileForm = () => {
  return (
    <LineWrapper>
      <IndividualLine style={{ display: "block", padding: "24px" }}>
        <RightColumnHeader>Оставьте заявку</RightColumnHeader>
        <RightColumnSubHeader>
          Узнайте больше о преимуществах
          <br />
          европейского гражданства
        </RightColumnSubHeader>
        <form action="https://formspree.io/f/maygjpqo" method="POST">
          <label>
            <RightColumnInput
              name="name"
              type="text"
              placeholder="Имя"
              required
            />
          </label>
          <label>
            <RightColumnInput
              name="contact"
              type="text"
              placeholder="WhatsApp / Telegram"
              required
            />
          </label>
          <label>
            <RightColumnSelect name="country" required>
              <option value="">Интересующая страна</option>
              <option value="Россия">Россия</option>
              <option value="Украина">Украина</option>
              <option value="Грузия">Грузия</option>
              <option value="Казахстан">Казахстан</option>
            </RightColumnSelect>
          </label>
          <RightColumnButton type="submit">Оставить заявку</RightColumnButton>
        </form>
      </IndividualLine>
    </LineWrapper>
  )
}

export default MobileForm
