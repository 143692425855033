import { createClient } from "contentful"

const useContentful = () => {
  const client = createClient({
    space: "fekpmvawe2th",
    accessToken: "ZTPzewFDTm5dbM9QX2UQAZIdcXEfCNUoZIaxHK6_jys",
    host: "preview.contentful.com",
  })
  const fetchContactInformation = async () => {
    return client.getEntries({
      content_type: "contactInformation",
      select: "fields,sys.createdAt",
      order: "sys.createdAt",
    })
  }

  const fetchRus = async () => {
    return client.getEntries({
      content_type: "rus",
      select: "fields,sys.createdAt",
      order: "sys.createdAt",
    })
  }

  const fetchStatics = async () => {
    return client.getEntries({
      content_type: "statics",
      select: "fields,sys.createdAt",
      order: "sys.createdAt",
    })
  }

  const fetchGR = async () => {
    return client.getEntries({
      content_type: "gr",
      select: "fields,sys.createdAt",
      order: "sys.createdAt",
    })
  }

  const fetchKZH = async () => {
    return client.getEntries({
      content_type: "kzh",
      select: "fields,sys.createdAt",
      order: "sys.createdAt",
    })
  }

  const fetchUKR = async () => {
    return client.getEntries({
      content_type: "ukr",
      select: "fields,sys.createdAt",
      order: "sys.createdAt",
    })
  }
  const getAllData = async () => {
    try {
      const [
        contactInformationEntries,
        rusEntries,
        staticsEntries,
        grEntries,
        kzhEntries,
        ukrEntries,
      ] = await Promise.all([
        fetchContactInformation(),
        fetchRus(),
        fetchStatics(),
        fetchGR(),
        fetchKZH(),
        fetchUKR(),
      ])

      return {
        contactInformation: contactInformationEntries.items.map(
          item => item.fields
        ),
        rus: rusEntries.items.map(item => item.fields),
        statics: staticsEntries.items.map(item => item.fields),
        gr: grEntries.items.map(item => item.fields),
        kzh: kzhEntries.items.map(item => item.fields),
        ukr: ukrEntries.items.map(item => item.fields),
      }
    } catch (error) {
      console.log(`Error fetching data ${error}`)
    }
  }

  return { getAllData }
}

export default useContentful
