import React from "react"
import {
  Icon,
  InfoContainer,
  ContactInfoDiv,
  ContactInfoLink,
  TextCountry,
  LinkNumber,
  TextAddress,
  LineWrapper,
  IndividualLine,
  ContainerFlex,
  ContactInfoLinkAddress,
} from "../StyledComponents"
import whatsapp from "../image/whatsapp.svg"
import telegram from "../image/telegram.svg"
import local from "../image/local.svg"

const ContactInfo = ({ contactInformation }) => {
  if (!contactInformation || contactInformation.length === 0) return null

  return (
    <LineWrapper>
      <IndividualLine>
        <InfoContainer>
          <ContactInfoDiv>
            <ContainerFlex>
              <TextCountry>{contactInformation[0].country}</TextCountry>
              <LinkNumber href={`tel:${contactInformation[0].phone}`}>
                {contactInformation[0].phone}
              </LinkNumber>
            </ContainerFlex>
            <ContainerFlex>
              <ContactInfoLink
                href={`https://t.me/${contactInformation[0].telegram}`}
              >
                <Icon style={{ marginRight: "8px" }} src={telegram} alt="" />
              </ContactInfoLink>
              <ContactInfoLink
                href={`https://wa.me/${contactInformation[0].whatsApp}`}
              >
                <Icon style={{ marginRight: "8px" }} src={whatsapp} alt="" />
              </ContactInfoLink>
            </ContainerFlex>
          </ContactInfoDiv>
          <ContactInfoDiv>
            <ContainerFlex>
              <TextCountry>{contactInformation[1].country}</TextCountry>
              <LinkNumber href={`tel:${contactInformation[0].phone}`}>
                {contactInformation[1].phone}
              </LinkNumber>
            </ContainerFlex>
            <ContainerFlex>
              <ContactInfoLink
                href={`https://t.me/${contactInformation[1].telegram}`}
              >
                <Icon style={{ marginRight: "8px" }} src={telegram} alt="" />
              </ContactInfoLink>
              <ContactInfoLink
                href={`https://wa.me/${contactInformation[1].whatsApp}`}
              >
                <Icon style={{ marginRight: "8px" }} src={whatsapp} alt="" />
              </ContactInfoLink>
            </ContainerFlex>
          </ContactInfoDiv>
          <ContactInfoDiv>
            <ContactInfoLinkAddress
              href={`https://www.google.com/maps/search/?api=1&query=${contactInformation[1].local.lat},${contactInformation[1].local.lon}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon src={local} alt="" style={{ marginRight: "11px" }} />
              <TextAddress>{contactInformation[1].localText}</TextAddress>
            </ContactInfoLinkAddress>
          </ContactInfoDiv>
        </InfoContainer>
      </IndividualLine>
    </LineWrapper>
  )
}

export default ContactInfo
