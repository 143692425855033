import React, { useState } from "react"
import {
  LineWrapper,
  IndividualLine,
  CountryServiceBreadcrumbs,
  CountryList,
  CountryItem,
  CountrySelectorText,
  CountrySelectorImg,
  ContainerCountryList,
  ContainerFlex,
  ContainerTwoColumnWidth,
  ColumnTitle,
  CountryListMain,
  CountryItemMain,
  CountryItemMainPreContainer,
  Container,
  ContainerMargin,
  SpanTitleMain,
  Description,
  ImageTask,
  ContainerFlexCenterMobile,
  ContainerMainFlex,
  SpanTitleMainOther,
} from "../StyledComponents"
import rusIcon from "../image/rus.svg"
import ukrIcon from "../image/ukr.svg"
import grIcon from "../image/gr.svg"
import kzhIcon from "../image/kzh.svg"
import taskIcon from "../image/task.svg"
import { useMediaQuery } from "../useMediaQuery"

export default function MainInfo({ rus, ukr, gr, kzh }) {
  const [selectedCountry, setSelectedCountry] = useState("Россия")

  const isMobile992 = useMediaQuery(992)

  return (
    <LineWrapper>
      <IndividualLine style={{ display: "block", padding: "24px 0" }}>
        <ContainerFlexCenterMobile>
          <CountryServiceBreadcrumbs>
            Страны / Список Услуг
          </CountryServiceBreadcrumbs>
        </ContainerFlexCenterMobile>
        <ContainerCountryList>
          <CountryList>
            <CountryItem
              $isActive={selectedCountry === "Россия"}
              onClick={() => setSelectedCountry("Россия")}
            >
              {!isMobile992 && (
                <CountrySelectorText>Россия</CountrySelectorText>
              )}
              <CountrySelectorImg alt="" src={rusIcon} />
            </CountryItem>
            <CountryItem
              $isActive={selectedCountry === "Украина"}
              onClick={() => setSelectedCountry("Украина")}
            >
              {!isMobile992 && (
                <CountrySelectorText>Украина</CountrySelectorText>
              )}
              <CountrySelectorImg alt="" src={ukrIcon} />
            </CountryItem>
            <CountryItem
              $isActive={selectedCountry === "Грузия"}
              onClick={() => setSelectedCountry("Грузия")}
            >
              {!isMobile992 && (
                <CountrySelectorText>Грузия</CountrySelectorText>
              )}
              <CountrySelectorImg alt="" src={grIcon} />
            </CountryItem>
            <CountryItem
              $isActive={selectedCountry === "Казахстан"}
              onClick={() => setSelectedCountry("Казахстан")}
            >
              {!isMobile992 && (
                <CountrySelectorText>Казахстан</CountrySelectorText>
              )}
              <CountrySelectorImg alt="" src={kzhIcon} />
            </CountryItem>
          </CountryList>
          {!isMobile992 && (
            <ContainerFlex>
              <ContainerTwoColumnWidth>
                <ColumnTitle>Рабочих дней</ColumnTitle>
              </ContainerTwoColumnWidth>
              <ColumnTitle>Цена</ColumnTitle>
            </ContainerFlex>
          )}
        </ContainerCountryList>
        {selectedCountry === "Россия" && (
          <CountryListMain>
            {rus?.map((item, index) => (
              <CountryItemMain key={index}>
                <ContainerMargin>
                  <ContainerMainFlex>
                    <ImageTask alt="" src={taskIcon} />
                    <CountryItemMainPreContainer>
                      <ContainerFlexCenterMobile>
                        <SpanTitleMain>{item?.title}</SpanTitleMain>
                      </ContainerFlexCenterMobile>
                      <ContainerFlexCenterMobile>
                        <Description>{item?.description}</Description>
                      </ContainerFlexCenterMobile>
                    </CountryItemMainPreContainer>
                  </ContainerMainFlex>
                  <ContainerFlex>
                    <ContainerTwoColumnWidth>
                      {isMobile992 && <ColumnTitle>Рабочих дней:</ColumnTitle>}
                      <Container>
                        {item.dayList?.map((price, index) => (
                          <SpanTitleMainOther key={index}>
                            {price}
                          </SpanTitleMainOther>
                        ))}
                      </Container>
                    </ContainerTwoColumnWidth>
                    <Container>
                      {isMobile992 && <ColumnTitle>Цена:</ColumnTitle>}
                      <Container>
                        {item.priceList?.map((price, index) => (
                          <SpanTitleMainOther key={index}>
                            {price}
                          </SpanTitleMainOther>
                        ))}
                      </Container>
                    </Container>
                  </ContainerFlex>
                </ContainerMargin>
              </CountryItemMain>
            ))}
          </CountryListMain>
        )}
        {selectedCountry === "Украина" && (
          <CountryListMain>
            {ukr?.map((item, index) => (
              <CountryItemMain key={index}>
                <ContainerMargin>
                  <ContainerMainFlex>
                    <ImageTask alt="" src={taskIcon} />
                    <CountryItemMainPreContainer>
                      <ContainerFlexCenterMobile>
                        <SpanTitleMain>{item?.title}</SpanTitleMain>
                      </ContainerFlexCenterMobile>
                      <ContainerFlexCenterMobile>
                        <Description>{item?.description}</Description>
                      </ContainerFlexCenterMobile>
                    </CountryItemMainPreContainer>
                  </ContainerMainFlex>
                  <ContainerFlex>
                    <ContainerTwoColumnWidth>
                      {isMobile992 && <ColumnTitle>Рабочих дней:</ColumnTitle>}
                      <Container>
                        {item.dayList?.map((price, index) => (
                          <SpanTitleMainOther key={index}>
                            {price}
                          </SpanTitleMainOther>
                        ))}
                      </Container>
                    </ContainerTwoColumnWidth>
                    <Container>
                      {isMobile992 && <ColumnTitle>Цена:</ColumnTitle>}
                      <Container>
                        {item.priceList?.map((price, index) => (
                          <SpanTitleMainOther key={index}>
                            {price}
                          </SpanTitleMainOther>
                        ))}
                      </Container>
                    </Container>
                  </ContainerFlex>
                </ContainerMargin>
              </CountryItemMain>
            ))}
          </CountryListMain>
        )}
        {selectedCountry === "Грузия" && (
          <CountryListMain>
            {gr?.map((item, index) => (
              <CountryItemMain key={index}>
                <ContainerMargin>
                  <ContainerMainFlex>
                    <ImageTask alt="" src={taskIcon} />
                    <CountryItemMainPreContainer>
                      <ContainerFlexCenterMobile>
                        <SpanTitleMain>{item?.title}</SpanTitleMain>
                      </ContainerFlexCenterMobile>
                      <ContainerFlexCenterMobile>
                        <Description>{item?.description}</Description>
                      </ContainerFlexCenterMobile>
                    </CountryItemMainPreContainer>
                  </ContainerMainFlex>
                  <ContainerFlex>
                    <ContainerTwoColumnWidth>
                      {isMobile992 && <ColumnTitle>Рабочих дней:</ColumnTitle>}
                      <Container>
                        {item.dayList?.map((price, index) => (
                          <SpanTitleMainOther key={index}>
                            {price}
                          </SpanTitleMainOther>
                        ))}
                      </Container>
                    </ContainerTwoColumnWidth>
                    <Container>
                      {isMobile992 && <ColumnTitle>Цена:</ColumnTitle>}
                      <Container>
                        {item.priceList?.map((price, index) => (
                          <SpanTitleMainOther key={index}>
                            {price}
                          </SpanTitleMainOther>
                        ))}
                      </Container>
                    </Container>
                  </ContainerFlex>
                </ContainerMargin>
              </CountryItemMain>
            ))}
          </CountryListMain>
        )}
        {selectedCountry === "Казахстан" && (
          <CountryListMain>
            {kzh?.map((item, index) => (
              <CountryItemMain key={index}>
                <ContainerMargin>
                  <ContainerMainFlex>
                    <ImageTask alt="" src={taskIcon} />
                    <CountryItemMainPreContainer>
                      <ContainerFlexCenterMobile>
                        <SpanTitleMain>{item?.title}</SpanTitleMain>
                      </ContainerFlexCenterMobile>
                      <ContainerFlexCenterMobile>
                        <Description>{item?.description}</Description>
                      </ContainerFlexCenterMobile>
                    </CountryItemMainPreContainer>
                  </ContainerMainFlex>
                  <ContainerFlex>
                    <ContainerTwoColumnWidth>
                      {isMobile992 && <ColumnTitle>Рабочих дней:</ColumnTitle>}
                      <Container>
                        {item.dayList?.map((price, index) => (
                          <SpanTitleMainOther key={index}>
                            {price}
                          </SpanTitleMainOther>
                        ))}
                      </Container>
                    </ContainerTwoColumnWidth>
                    <Container>
                      {isMobile992 && <ColumnTitle>Цена:</ColumnTitle>}
                      <Container>
                        {item.priceList?.map((price, index) => (
                          <SpanTitleMainOther key={index}>
                            {price}
                          </SpanTitleMainOther>
                        ))}
                      </Container>
                    </Container>
                  </ContainerFlex>
                </ContainerMargin>
              </CountryItemMain>
            ))}
          </CountryListMain>
        )}
      </IndividualLine>
    </LineWrapper>
  )
}
