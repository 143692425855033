import backgroundImage from "./image/backgroundImage-min.jpeg"
import styled from "styled-components"

const AppContainer = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    url(${backgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ContentContainer = styled.div`
  width: 100%;
  max-width: 1701px;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
  @media (min-width: 1701px) {
    justify-content: space-between;
  }
`

const LineWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  @media (max-width: 992px) {
    margin-bottom: 24px;
  }
`

const LineWrapperStatics = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 32px;
  @media (max-width: 992px) {
    gap: 16px;
    flex-direction: column;
  }
`

const IndividualLine = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex: 1;
  border-radius: 16px;
  padding: 24px 32px;
  background: #fff;
  justify-content: center;
  @media (max-width: 992px) {
    flex-direction: column;
  }
`

const Icon = styled.img`
  width: 16px;
`

const RightColumn = styled.div`
  flex-shrink: 0;
  width: 344px;
  height: 536px;
  background: #fff;
  border-radius: 16px;
  padding: 24px 24px 32px;
  @media (max-width: 1700px) {
    margin-top: 32px;
  }
`

const LeftColumn = styled.div`
  width: 100%;
  @media (min-width: 1701px) {
    width: 1285px;
  }
`

const RightColumnHeader = styled.h2`
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  color: rgba(33, 43, 54, 1);
  margin-bottom: 4px;
  @media (max-width: 992px) {
    display: flex;
    justify-content: center;
    font-size: 14px;
  }
`

const RightColumnSubHeader = styled.p`
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: rgba(99, 115, 129, 1);
  margin-bottom: 69px;
  @media (max-width: 992px) {
    display: flex;
    justify-content: center;
    font-size: 9px;
    text-align: center;
    line-height: 16px;
    color: #212b36;
    margin-bottom: 18px;
  }
`

const RightColumnInput = styled.input`
  margin-bottom: 35px;
  outline: none;
  display: block;
  width: 100%;
  color: rgba(145, 158, 171, 1);
  padding: 8px 0;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  border-bottom: 1px solid rgba(145, 158, 171, 0.2);
  &::placeholder {
    color: rgba(145, 158, 171, 1);
  }
  @media (max-width: 992px) {
    font-size: 12px;
    margin-bottom: 12px;
  }
`

const RightColumnButton = styled.button`
  margin: auto;
  margin-top: 60px;
  display: block;
  border-radius: 40px;
  padding: 20px 32px;
  background-color: rgba(58, 185, 144, 1);
  color: #fff;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.05);
  }
  @media (max-width: 992px) {
    padding: 12px 32px;
    margin-top: 24px;
    font-size: 14px;
  }
`

const RightColumnSelect = styled.select`
  background: none;
  outline: none;
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none"><path d="M10.0004 13.4178C9.8057 13.4182 9.61701 13.3503 9.46708 13.2261L4.46708 9.05944C4.1127 8.76488 4.06419 8.23882 4.35874 7.88444C4.6533 7.53005 5.17936 7.48155 5.53374 7.7761L10.0004 11.5094L14.4671 7.90944C14.6392 7.76963 14.86 7.70421 15.0806 7.72767C15.3011 7.75113 15.5032 7.86154 15.6421 8.03444C15.7964 8.20765 15.8715 8.43733 15.8493 8.66824C15.8272 8.89914 15.7098 9.11036 15.5254 9.2511L10.5254 13.2761C10.3712 13.3807 10.1863 13.4306 10.0004 13.4178Z" fill="%23637381"/></svg>');
  background-repeat: no-repeat;
  background-position: right;
  display: block;
  width: 100%;
  color: rgba(145, 158, 171, 1);
  padding: 8px 0;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  border-bottom: 1px solid rgba(145, 158, 171, 0.2);
  @media (max-width: 992px) {
    font-size: 12px;
  }
`

const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  @media (max-width: 992px) {
    flex-direction: column;
  }
`
const ContactInfoDiv = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 992px) {
    flex-direction: column-reverse;
  }
`

const ContactInfoLink = styled.a`
  display: flex;
  align-items: center;
  @media (max-width: 992px) {
    margin-bottom: 8px;
  }
`

const ContactInfoLinkAddress = styled.a`
  display: flex;
  align-items: center;
  @media (max-width: 992px) {
    flex-direction: column;
  }
`

const TextCountry = styled.span`
  margin-right: 5px;
  color: #3ab990;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
`

const LinkNumber = styled.a`
  color: #212b36;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-right: 15px;
  @media (max-width: 992px) {
    margin-bottom: 16px;
    font-size: 12px;
  }
`

const TextAddress = styled.span`
  color: #212b36;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  @media (max-width: 992px) {
    margin-top: 8px;
    font-size: 12px;
    text-align: center;
  }
`

const StaticsText = styled.span`
  color: #3ab990;
  font-family: Montserrat;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-right: 24px;
  @media (max-width: 992px) {
    font-size: 20px;
    margin-bottom: 8px;
    margin-right: 0px;
  }
`

const StaticsTextRight = styled.span`
  color: #212b36;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  @media (max-width: 992px) {
    font-size: 9px;
  }
`

const Loader = styled.div`
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #3498db;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin: auto;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const CountryServiceBreadcrumbs = styled.span`
  color: #212b36;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  margin-left: 24px;
  @media (max-width: 992px) {
    font-size: 14px;
  }
`

const ContainerFlexCenterMobile = styled.div`
  @media (max-width: 992px) {
    display: flex;
    justify-content: center;
  }
`

const CountryList = styled.ul`
  width: 890px;
  display: flex;
  padding-right: 10px;
  flex-wrap: wrap;
  gap: 12px;
  @media (max-width: 992px) {
    gap: 8px;
    justify-content: center;
  }
`

const CountryItem = styled.li`
  cursor: pointer;
  display: flex;
  width: max-content;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #e7e7e7;
  background: ${props => (props.$isActive ? "#3ab990" : "#fff")};
  padding: 6px 10px;
  @media (max-width: 992px) {
    padding: 4px 6px;
  }
`

const CountrySelectorText = styled.span`
  color: #353535;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: 12px;
`

const CountrySelectorImg = styled.img`
  margin-bottom: -8px;
  width: 58px;
`

const ContainerCountryList = styled.div`
  display: flex;
  align-items: center;
  margin: 34px 32px 21px;
  justify-content: space-between;
  @media (max-width: 992px) {
    margin: 32px 10px 20px;
  }
`

const ContainerFlex = styled.div`
  display: flex;
`

const ContainerMainFlex = styled.div`
  display: flex;
  @media (max-width: 992px) {
    flex-direction: column;
  }
`

const ContainerTwoColumnWidth = styled.div`
  width: 180px;
  @media (max-width: 992px) {
    width: auto;
    margin-right: 8px;
  }
`

const ColumnTitle = styled.span`
  color: #637381;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  min-width: 150px;
  @media (max-width: 992px) {
    font-size: 9px;
    line-height: 16px;
    min-width: auto;
    color: #212b36;
  }
`

const CountryListMain = styled.ul``

const CountryItemMain = styled.li`
  padding: 16px 0;
  &:not(:last-child) {
    border-bottom: 1px dashed rgba(145, 158, 171, 0.5);
  }
  @media (max-width: 992px) {
    padding: 12px 0;
    &:last-child {
      padding-bottom: 0;
    }
  }
`

const CountryItemMainPreContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  @media (max-width: 992px) {
    flex-direction: column;
    gap: 4px;
    justify-content: center;
    padding-right: 0px;
  }
`

const Container = styled.div``

const ContainerMargin = styled.div`
  margin: 0 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 992px) {
    flex-direction: column;
    justify-content: center;
  }
`

const SpanTitleMain = styled.p`
  color: #212b36;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  min-width: 150px;
  @media (max-width: 992px) {
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    min-width: auto;
  }
`

const SpanTitleMainOther = styled.p`
  color: #212b36;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  min-width: 150px;
  @media (max-width: 992px) {
    font-size: 9px;
    line-height: 16px;
    min-width: auto;
  }
`

const Description = styled.p`
  color: #637381;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  @media (max-width: 992px) {
    font-size: 9px;
    line-height: 16px;
    margin-bottom: 8px;
    text-align: center;
  }
`

const ImageTask = styled.img`
  height: 24px;
  margin-right: 19px;
  @media (max-width: 992px) {
    margin-right: 0px;
    margin-bottom: 8px;
  }
`

export {
  AppContainer,
  ContentContainer,
  LineWrapper,
  IndividualLine,
  RightColumn,
  LeftColumn,
  RightColumnHeader,
  RightColumnSubHeader,
  RightColumnInput,
  RightColumnButton,
  RightColumnSelect,
  Icon,
  InfoContainer,
  ContactInfoDiv,
  ContactInfoLink,
  TextCountry,
  LinkNumber,
  TextAddress,
  StaticsText,
  StaticsTextRight,
  Loader,
  CountryServiceBreadcrumbs,
  CountryList,
  CountryItem,
  CountrySelectorText,
  CountrySelectorImg,
  ContainerCountryList,
  ContainerFlex,
  ContainerTwoColumnWidth,
  ColumnTitle,
  CountryListMain,
  CountryItemMain,
  CountryItemMainPreContainer,
  Container,
  ContainerMargin,
  SpanTitleMain,
  Description,
  ImageTask,
  ContactInfoLinkAddress,
  ContainerFlexCenterMobile,
  ContainerMainFlex,
  SpanTitleMainOther,
  LineWrapperStatics,
}
